


























import { Component, Prop, Vue } from "vue-property-decorator";
import ArticleListItem from "@/components/shop/article/list/ArticleListItem.vue";
import { Deal } from "@/types/shop/article";

@Component({
    components: {
        ArticleListItem,
    },
})
export default class ShopDealListItem extends Vue {
    @Prop({ type: Object, required: true }) readonly deal!: Deal;
}
