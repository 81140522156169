
















import { Component, Prop, Vue } from "vue-property-decorator";
import ArticleListItem from "./ArticleListItem.vue";
import { Article } from "@/types/shop/article";

/**
 * ArticleList component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ArticleListItem,
    },
})
export default class ArticleList extends Vue {
    @Prop({ type: Boolean, default: false }) readonly showProperties!: boolean;
    @Prop({ type: Boolean, default: false })
    readonly showAddToCartButton!: boolean;
    @Prop({ type: Array, required: true }) readonly articles!: Article[];
}
